<template>
  <div>
    <v-data-table
      :loading="isTableLoading"
      loading-text="Cargando..."
      :server-items-length="itemsTotalCount"
      :options.sync="tableOptions"
      :headers="tableHeaders"
      item-key="id"
      :items="items"
      sort-by="itemDateTime"
      class="elevation-4"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pa-2">
          <input
            v-show="false"
            type="file"
            ref="fileInput"
            @change="onPDFSelected"
            accept="application/pdf"
          />

          <v-btn
            :disabled="isTableLoading"
            color="primary"
            @click="newItemClick"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            Nuevo envío
          </v-btn>

          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="dialogFiltering = true"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiFilterOutline }}
            </v-icon>
            Filtrar
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="getItems"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.itemDateTime`]="{ item }">
        {{ new Date(item.itemDateTime).toLocaleString() }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon small class="mr-1" :color="getStatusColor(item.status)">
          {{ mdiCircle }}
        </v-icon>
        {{ getStatusText(item.status) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="showItemAuditTrail(item)"
            >
              <v-icon>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar detalle</span>
        </v-tooltip>
        <v-menu transition="scale-transition" absolute min-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="openMoreOptionsMenu(item)"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list v-if="moreOptionsMenu" ref="listTest">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="documentDetailsLoading"
              ></v-progress-circular>
            </div>

            <div v-if="hasAnyAction(item.status)">
              <v-list-item
                v-if="isCancelable(item.status) && !documentDetailsLoading"
                @click="cancelItem(item)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Cancelar documento</span>
                </v-tooltip>
                <span class="ml-1">Cancelar documento</span>
              </v-list-item>

              <v-list-item
                v-if="isDownloadable(item.status) && !documentDetailsLoading"
                @click="downloadDocument(item)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs">
                      <v-icon>
                        mdi-download-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Descargar documento</span>
                </v-tooltip>
                <span class="ml-1">Descargar documento</span>
              </v-list-item>
            </div>
            <div v-else-if="!documentDetailsLoading">
              <v-list-item>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>
                        mdi-close-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Sin acciones</span>
                </v-tooltip>
                <span class="ml-1">Sin acciones</span>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </template>

      <template v-if="getHistoricSignFromAppItemsError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="getItems()">
          Reintentar
        </v-btn>
      </template>

      <template v-slot:footer.prepend>
        <v-row>
          <v-spacer> </v-spacer>
          <v-btn
            icon
            class="mr-4"
            @click="loadFirstsItems"
            :disabled="isTableLoading || tableOptions.page === 1"
          >
            <v-icon>
              {{ mdiChevronDoubleLeft }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            class="mr-8"
            @click="loadLastsItems"
            :disabled="
              isTableLoading ||
                tableOptions.page * tableOptions.itemsPerPage >= itemsTotalCount
            "
          >
            <v-icon>
              {{ mdiChevronDoubleRight }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog scrollable v-model="dialogFiltering" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Filtros
        </v-card-title>

        <v-card-text>
          <v-select
            class="mt-4"
            v-model="itemFilteringStatus"
            :items="documentStatus"
            label="Estados"
            prepend-inner-icon="mdi-magnify"
            outlined
            multiple
            clearable
            small-chips
            dense
          ></v-select>

          <v-menu
            ref="filteringDatesMenu"
            v-model="filteringDatesMenu"
            :close-on-content-click="false"
            :return-value.sync="itemFilteringDates"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mt-2"
                :value="itemFilteringDatesText"
                label="Rango de fechas"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                outlined
                clearable
                @click:clear="clearFilteringDates"
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :allowed-dates="allowedItemFilteringDates"
              v-model="itemFilteringDates"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="filteringDatesMenu = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="itemFilteringDates.length < 2"
                @click="updateDates"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="dialogFiltering = false">
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="filterItems">
            Filtrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogCancel" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="primary">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text
          class="font-weight-bold headline justify-center text-center"
        >
          Confirmar cancelación
        </v-card-text>
        <v-card-text
          class="text-center pb-3 color-grey-text"
          v-html="cancelTextConfirmation()"
        ></v-card-text>
        <v-card-actions class="justify-center pa-4">
          <v-btn color="primary" text @click="closeCancel()">Volver</v-btn>
          <v-btn
            :loading="isCancelling"
            class="ml-5  "
            color="primary"
            @click="cancelItemConfirm()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="dialogAuditTrail" max-width="800">
      <div v-if="documentSetAuditTrail == null">
        <v-card class="pa-1" color="primary" dark>
          <v-card-text>
            <span>Recuperando eventos...</span>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-2"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </div>

      <v-card v-if="documentSetAuditTrail != null">
        <v-card-title class="text-h5">
          Ficha eventos
        </v-card-title>

        <v-card-text>
          <h4>Nombre del sobre</h4>
          <p>
            {{ selectedItem && selectedItem.deviceDocumentName }}
          </p>

          <h4>Número total de eventos</h4>
          <p>
            {{ documentSetAuditTrail.length }}
          </p>
          <h4 v-if="documentRejectReason">Motivo de rechazo</h4>
          <p v-if="documentRejectReason">
            {{ documentRejectReason }}
          </p>
          <h4>Cronología</h4>

          <v-timeline align-top dense class="mt-2">
            <div :key="i" v-for="(event, i) in documentSetAuditTrail">
              <v-timeline-item small>
                <v-row>
                  <v-col cols="12" sm="4" class="pt-0">
                    <strong>
                      {{ new Date(event.eventDateTime).toLocaleString() }}
                    </strong>
                  </v-col>
                  <v-col class="pt-0">
                    <h4 class="primary--text">
                      {{ getEventAuditTrail(event.eventType) }}
                    </h4>
                    <div class="caption ml-1 mt-1" v-if="event.recipientId">
                      <v-icon small>mdi-account</v-icon>
                      <span class="color-grey-text">
                        Participante:
                      </span>
                    </div>
                    <div class="caption ml-1 mt-1" v-if="event.documentId">
                      <v-icon small>mdi-file-document</v-icon>
                      <span class="color-grey-text">
                        Documento:
                      </span>
                    </div>
                    <div
                      class="caption ml-1 mt-1"
                      v-if="event.data && event.data.MotivoRechazo"
                    >
                      <v-icon small>mdi-cancel</v-icon>
                      <span class="color-grey-text">
                        Motivo de rechazo : {{ event.data.MotivoRechazo }}
                      </span>
                    </div>
                    <div
                      class="caption ml-1 mt-1"
                      v-if="event.data && event.data.pageNumber"
                    >
                      <v-icon small>mdi-book-open-page-variant</v-icon>
                      <span class="color-grey-text">
                        Número de página : {{ event.data.pageNumber }}
                      </span>
                    </div>
                    <div
                      class="caption ml-1 mt-1"
                      v-if="event.data && event.data.IpAddress"
                    >
                      <v-icon small>mdi-router-wireless</v-icon>
                      <span class="color-grey-text">
                        Dirección IP: {{ event.data.IpAddress }}</span
                      >
                    </div>
                    <div
                      class="caption ml-1 mt-1"
                      v-if="event.data && event.data.UserAgent"
                    >
                      <v-icon small>mdi-web</v-icon>
                      <span class="color-grey-text">
                        Navegador Web : {{ event.data.UserAgent }}</span
                      >
                    </div>
                    <div
                      class="caption ml-1 mt-1"
                      v-if="event.data && event.data.Gps"
                    >
                      <v-icon small>mdi-map-marker</v-icon>
                      <span class="color-grey-text">
                        Localización : {{ event.data.Gps }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </div>
          </v-timeline>
          <span class="ml-6 caption">
            * Todas las fechas se muestran tomando como referencia la hora local
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="updatingSeeEventsDetail"
            @click="detailEventsRefresh()"
            text
            color="primary"
          >
            Refrescar
          </v-btn>

          <v-spacer> </v-spacer>
          <v-btn @click="dialogAuditTrail = false" text color="primary">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="isDownloading" persistent max-width="344">
      <v-card color="primary" dark>
        <v-card-text class="pa-4">
          <span v-html="downloadingText()"></span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SetAddDay } from "../../../utils/utils";
import {
  mdiCancel,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiCircle,
  mdiFilterOutline,
  mdiPlus,
  mdiRefresh
} from "@mdi/js";

export default {
  name: "FaceToFaceSignatures",

  data: () => ({
    mdiPlus: mdiPlus,
    mdiRefresh: mdiRefresh,
    mdiFilterOutline: mdiFilterOutline,
    mdiCircle: mdiCircle,
    mdiChevronDoubleLeft: mdiChevronDoubleLeft,
    mdiChevronDoubleRight: mdiChevronDoubleRight,
    mdiCancel: mdiCancel,
    moreOptionsMenu: false,
    documentDetailsLoading: false,
    tableHeaders: [
      {
        text: "NOMBRE",
        value: "deviceDocumentName"
      },
      {
        text: "FECHA",
        value: "itemDateTime"
      },
      { text: "ESTADO", value: "status" },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    tableOptions: {},
    itemFilteringDates: [],
    filteringDatesMenu: false,
    documentStatus: [
      { value: 100, text: "Creado" },
      { value: 200, text: "En proceso" },
      { value: 300, text: "Completado" },
      { value: 400, text: "Cancelado" },
      { value: 500, text: "Rechazado" },
      { value: 600, text: "Error al devolver documento firmado" },
      { value: 700, text: "Firmado y entregado" },
      { value: 800, text: "Error notificado por dispositivo" },
      { value: 900, text: "Eliminado previo envio a dispositivo" },
      { value: 1000, text: "Expirado" }
    ],
    itemFilteringStatus: [],
    selectedItem: null,
    getHistoricSignFromAppItemsError: false,

    documentSetAuditTrail: undefined,
    isCancelling: false,
    isTableLoading: false,
    isDownloading: false,
    dialogFiltering: false,
    dialogCancel: false,
    dialogAuditTrail: false,
    updatingSeeEventsDetail: false,
    documentRejectReason: null
  }),
  watch: {
    dialogCancel(val) {
      val || this.closeCancel();
    },

    tableOptions: {
      deep: true,
      handler() {
        this.getItems();
      }
    }
  },

  computed: {
    itemFilteringDatesText() {
      var itemFilteringDates = [];
      if (this.itemFilteringDates.length > 0) {
        for (var i = 0; i < this.itemFilteringDates.length; i++) {
          const [year, month, day] = this.itemFilteringDates[i].split("-");
          itemFilteringDates.push(`${day}/${month}/${year}`);
        }
      }
      return itemFilteringDates.join(" ~ ");
    },

    items() {
      return this.$store.getters.historicSignFromAppItems;
    },

    itemsTotalCount() {
      return this.$store.getters.historicCountSignFromAppItems;
    }
  },

  methods: {
    hasAnyAction(status) {
      return this.isCancelable(status) || this.isDownloadable(status);
    },
    filterItems() {
      this.tableOptions.page = 1;
      this.dialogFiltering = false;
      this.getItems();
    },

    showItemAuditTrail(item) {
      this.selectedItem = item;
      this.getDocumentAuditTrail();
      this.dialogAuditTrail = true;
    },

    getDocumentAuditTrail(isUpdated = false, onSuccess) {
      if (!isUpdated) this.documentSetAuditTrail = null;
      this.$store
        .dispatch("getAuditTrailSFAItem", this.selectedItem.id)
        .then(response => {
          if (response.status === 200) {
            if (isUpdated) {
              this.$toasted.global.toast_success({
                message: "Actualizado correctamente."
              });
            }
            this.documentRejectReason = null;
            this.processSuccessAuditTrail(response);
            this.documentDetailsLoading = false;
            if (onSuccess) onSuccess();
          } else {
            this.processErrorAuditTrail();
          }
        })
        .catch(err => {
          console.log(err);
          this.processErrorAuditTrail();
        });
    },

    getEventAuditTrail(eventId) {
      switch (eventId) {
        case 100:
          return "Recibido en FirmarOnline, pendiente enviar al dispositivo";
        case 200:
          return "Documento en dispositivo, pendiente de firma";
        case 300:
          return "Firmado";
        case 400:
          return "Eliminado en dispositivo";
        case 500:
          return "Rechazado en dispositivo";
        case 600:
          return "Error al recuperar documento firmado desde el dispositivo";
        case 700:
          return "Documento firmado y entregado";
        case 800:
          return "El dispositivo notifica un error";
        case 900:
          return "Eliminado antes de enviar al dispositivo";
        case 1000:
          return "Documento expirado / caducado";
        case 1100:
          return "Documento borrado";
        case 1200:
          return "Documento descargado";
        default:
          return "Acción del sistema";
      }
    },

    processSuccessAuditTrail(response) {
      this.documentSetAuditTrail = response.data;
      this.documentSetAuditTrail.forEach(element => {
        if (element.eventType === 500) {
          this.documentRejectReason = element.data?.reason ?? null;
        }
      });

      this.documentSetAuditTrail.sort(function(a, b) {
        if (a.eventDateTime < b.eventDateTime) {
          return 1;
        }
        if (a.eventDateTime > b.eventDateTime) {
          return -1;
        }
        return 0;
      });
    },

    processErrorAuditTrail() {
      this.$toasted.global.toast_error({
        message:
          "No se han podido recuperar los eventos del sobre: " +
          this.selectedItem.deviceDocumentName
      });
      this.dialogAuditTrail = false;
      this.resetSelectedItem();
    },

    loadFirstsItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    loadLastsItems() {
      let page = Math.ceil(
        this.itemsTotalCount / this.tableOptions.itemsPerPage
      );
      if (this.tableOptions.page !== page) {
        this.tableOptions.page = page;
      } else {
        this.getItems();
      }
    },

    isCancelable(status) {
      return status === 100;
    },

    detailEventsRefresh() {
      this.updatingSeeEventsDetail = true;
      this.getDocumentAuditTrail(true, () => {
        setTimeout(() => {
          this.updatingSeeEventsDetail = false;
        }, 500);
      });
    },

    closeCancel() {
      this.dialogCancel = false;
    },

    isDownloadable(status) {
      return status === 300;
    },

    downloadingText() {
      if (this.selectedItem != null) {
        return (
          "Descargando documento <strong>" +
          this.selectedItem.deviceDocumentName +
          "</strong>"
        );
      }
      return "";
    },

    getStatusText(status) {
      var statusFound = this.documentStatus.find(element => {
        return element.value == status;
      });
      return statusFound != undefined ? statusFound.text : "";
    },

    getStatusColor(status) {
      switch (status) {
        case 100:
          return "#20a9ef";
        case 200:
          return "#20a9ef";
        case 300:
          return "#52bc03";
        case 400:
          return "#e54448";
        case 500:
          return "#f5ad00";
        case 600:
          return "#e54448";
        case 700:
          return "#52bc03";
        case 800:
          return "#e54448";
        case 900:
          return "#e54448";
        case 1000:
          return "#e54448";
        default:
          return "#20a9ef";
      }
    },

    onPDFSelected(event) {
      const selectedFile = event.target.files[0];
      this.$router.push({
        name: "NewFaceToFaceSignature",
        params: { file: selectedFile }
      });
    },
    newItemClick() {
      this.$refs.fileInput.click();
    },

    /** HISTORIC */
    clearFilteringStatus() {
      this.itemFilteringStatus = [];
      this.getItems();
    },

    allowedItemFilteringDates(val) {
      if (this.itemFilteringDates.length === 1) {
        return val >= this.itemFilteringDates[0];
      }
      return val;
    },

    clearFilteringDates() {
      this.itemFilteringDates = [];
    },

    updateDates() {
      this.$refs.filteringDatesMenu.save(this.itemFilteringDates);
    },

    getItems() {
      let itemFilteringDates = [];

      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;

      if (this.itemFilteringDates && this.itemFilteringDates.length === 2) {
        itemFilteringDates = JSON.parse(
          JSON.stringify(this.itemFilteringDates)
        );
        itemFilteringDates[1] = SetAddDay(this.itemFilteringDates[1]);
      }
      let itemFilteringStatus = this.itemFilteringStatus;

      this.isTableLoading = true;
      this.$store
        .dispatch("getHistoricSignFromAppItems", {
          itemFilteringStatus,
          itemFilteringDates,
          limit,
          offset
        })
        .then(() => {
          this.getHistoricSignFromAppItemsError = false;
          this.isTableLoading = false;
        })
        .catch(err => {
          this.getHistoricSignFromAppItemsError = true;
          this.isTableLoading = false;
          console.log(err);
        });
    },
    /** END HISTORIC */

    /** DOWNLOAD DOCUMENT */

    downloadDocument(item) {
      this.selectedItem = item;
      this.isDownloading = true;
      this.$store
        .dispatch("downloadSignFromAppItem", this.selectedItem.id)
        .then(response => {
          if (response.status == 200) {
            this.processSuccessDownload(response.data);
          } else {
            this.processErrorDownload();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorDownload();
        });
    },

    processSuccessDownload(data) {
      this.isDownloading = false;
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        this.appendPDFExtensionIfNecessary(this.selectedItem.deviceDocumentName)
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.selectedItem = null;
    },

    appendPDFExtensionIfNecessary(name) {
      if (name.toLowerCase().endsWith(".pdf")) {
        return name;
      }
      return name + ".pdf";
    },

    processErrorDownload() {
      this.isDownloading = false;
      this.$toasted.global.toast_error({
        message:
          "El documento: " +
          this.selectedItem.deviceDocumentName +
          ", no se ha podido descargar"
      });
      this.selectedItem = null;
    },
    /** END DOWNLOAD DOCUMENT */

    /** CANCEL DOCUMENT **/
    cancelItem(item) {
      this.selectedItem = item;
      this.dialogCancel = true;
    },

    cancelItemConfirm() {
      this.isCancelling = true;
      this.cancelDocument();
    },

    cancelTextConfirmation() {
      if (this.selectedItem != null) {
        return (
          "¿Desea confirmar la cancelación del documento <strong>" +
          this.selectedItem.deviceDocumentName +
          "</strong>?"
        );
      }
      return "";
    },

    cancelDocument() {
      this.isCancelling = true;
      this.$store
        .dispatch("cancelSignFromAppItem", this.selectedItem.id)
        .then(response => {
          if (response.status === 200) {
            this.processSuccessCancel();
          } else {
            this.processErrorCancel();
          }
        })
        .catch(err => {
          console.log(err);
          this.processErrorCancel();
        });
    },

    processSuccessCancel() {
      var editedIndex = this.items.indexOf(this.selectedItem);
      this.selectedItem.status = 400;
      Object.assign(this.items[editedIndex], this.selectedItem);
      this.dialogCancel = false;
      this.isCancelling = false;
      this.$toasted.global.toast_success({
        message:
          "El documento: " +
          this.selectedItem.deviceDocumentName +
          ", se ha cancelado correctamente"
      });
      this.resetSelectedItem();
    },

    processErrorCancel() {
      this.dialogCancel = false;
      this.isCancelling = false;
      this.$toasted.global.toast_error({
        message:
          "El documento: " +
          this.selectedItem.deviceDocumentName +
          ", no se ha podido cancelar"
      });
      this.resetSelectedItem();
    },
    //** END CANCEL DOCUMENT */

    resetSelectedItem() {
      this.editedIndex = -1;
      this.selectedItem = -1;
    },
    openMoreOptionsMenu(item) {
      this.moreOptionsMenu = true;
      this.selectedItem = item;
      this.documentDetailsLoading = true;
      this.getDocumentAuditTrail();
    }
  }
};
</script>
<style scoped>
@media (max-width: 560px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
