<template>
  <div class="pb-4">
    <v-data-table
      :loading="loading"
      loading-text="Cargando..."
      :items-per-page="50"
      :headers="headers"
      item-key="deviceId"
      :items="userDevices"
      sort-by="deviceId"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pa-2">
          <v-btn
            :disabled="loading"
            outlined
            color="primary"
            @click="showAssociateDeviceDialog"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            Asociar dispositivo
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="loading"
            outlined
            color="primary"
            @click="getUserProfile()"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              v-on="on"
              @click="showEditDeviceDialog(item)"
            >
              <v-icon>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar detalle</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="makeFavItem(item)">
              <v-icon>
                {{ getFavoriteIcon(item) }}
              </v-icon>
            </v-btn>
          </template>
          <span>Seleccionar como predeterminado</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" icon v-on="on" @click="removeItem(item)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>

      <template v-if="getProfileError" v-slot:no-data>
        <v-btn class="ma-4" color="primary" @click="getProfile">
          Reintentar
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog scrollable v-model="removeDeviceDialog" max-width="350">
      <v-card>
        <v-card-title class="headline justify-center mb-4">
          <v-icon size="70" color="primary">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-subtitle
          class="font-weight-bold headline justify-center text-center"
        >
          Confirmar eliminación
        </v-card-subtitle>
        <v-card-text
          class="text-center pb-3 color-grey-text"
          v-html="removeTextConfirmation()"
        ></v-card-text>
        <v-card-actions class="justify-center pa-4">
          <v-btn color="primary" text @click="closeRemove()">Volver</v-btn>
          <v-btn
            :loading="isRemoving"
            class="ml-5  "
            color="primary"
            @click="removeItemConfirm()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      scrollable
      content-class="dialog-custom-form"
      v-model="associateDeviceDialog"
      max-width="500"
    >
      <v-form ref="form" v-on:submit.prevent class="dialog-custom-form">
        <v-card>
          <v-card-title class="text-h5">
            Asociar dispositivo
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field
                class="mt-2"
                v-model="editedDevice.alias"
                label="Alias"
                :rules="aliasRules"
                :counter="40"
                prepend-inner-icon="mdi-cellphone"
                :disabled="!havePlanWriteMode"
                required
                outlined
                clearable
                dense
              ></v-text-field>

              <v-alert text outlined type="info" border="left">
                <b>Escanear un código QR:</b> Simplemente apunta la cámara de tu
                dispositivo al código QR proporcionado.
                <br />

                <b> Usar un enlace directo:</b> Haz clic en el enlace que te
                proporcionamos y serás llevado directamente al proceso de
                conexión en la aplicación. .
              </v-alert>
              <v-radio-group v-model="associateType" row>
                <v-radio label="Código QR" value="qr" class="mt-3"></v-radio>
                <v-radio
                  label="Enlace directo"
                  value="deeplink"
                  class="mt-3"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="associateDeviceDialog = false" text color="primary">
              Cerrar
            </v-btn>
            <v-btn
              text
              :loading="isLoading"
              color="primary"
              @click="associateDevice"
              :disabled="!havePlanWriteMode || !editedDevice.alias"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      scrollable
      persistent
      v-model="associateDeviceDialogQR"
      max-width="500"
    >
      <v-form ref="form" class="dialog-custom-info">
        <v-card>
          <v-card-title class="text-h5">
            Asociar dispositivo
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-text-field
                label="Alias"
                disabled
                class="mb-3"
                :counter="40"
                :rules="aliasRules"
                v-model="editedDevice.alias"
                required
              ></v-text-field>

              <v-alert text outlined type="info" border="left">
                Para continuar es necesario tener instalada la aplicación de
                FirmarOnline SFA en su dispositivo Android o iOS disponible en
                las principales tiendas de aplicaciones.
              </v-alert>

              <a @click="openAppGooglePlay" class="mb-1">
                Clic aquí para acceder a Google Play (Android)
              </a>
              <p>
                En Android, una vez instalada click en Asociar dispositivo y
                escanear el código QR que aparece en esta pantalla.
              </p>
              <a @click="openAppStore" class="mb-1">
                Clic aquí para acceder a la App Store (iOS)
              </a>
              <p>
                En iOS, una vez instalada click en Asociar dispositivo y
                escanear el código QR que aparece en esta pantalla.
              </p>
            </v-container>
            <div id="canvas-container">
              <canvas
                ref="qrcode_canvas"
                style="max-height: 11rem;max-width: 11rem;"
              ></canvas>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="associateDeviceDialogQR = false"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      scrollable
      persistent
      v-model="associateDeviceDialogDeeplink"
      max-width="500"
    >
      <v-form ref="form" class="dialog-custom-info">
        <v-card>
          <v-card-title class="text-h5">
            Asociar dispositivo
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-text-field
                label="Alias"
                disabled
                class="mb-3"
                :counter="40"
                :rules="aliasRules"
                v-model="editedDevice.alias"
                required
              ></v-text-field>

              <a ref="deeplink" v-if="deeplink" :href="deeplink">
                {{ deeplink }}
              </a>
              <p v-else>
                No hay deeplink
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click="copyToClipboard"
              >Copiar
              <v-icon right>
                mdi-content-copy
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="associateDeviceDialogDeeplink = false"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog scrollable v-model="editDeviceDialog" max-width="500">
      <v-form ref="form" class="dialog-custom-form">
        <v-card>
          <v-card-title class="text-h5">
            Detalle dispositivo
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-text-field
                v-model="editedDevice.alias"
                label="Alias"
                :rules="aliasRules"
                :counter="40"
                prepend-inner-icon="mdi-cellphone"
                :disabled="!havePlanWriteMode"
                required
                outlined
                multiple
                clearable
                small-chips
                dense
              ></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="editDeviceDialog = false">
              Cerrar
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isEditing"
              @click="editDeviceApi"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { mdiPlus, mdiRefresh } from "@mdi/js";

export default {
  name: "DeviceFaceToFaceSignatures",

  data: () => ({
    mdiRefresh: mdiRefresh,
    mdiPlus: mdiPlus,
    userDevices: [],
    isEditing: false,
    isLoading: false,
    associateDeviceDialog: false,
    associateDeviceDialogQR: false,
    associateDeviceDialogDeeplink: false,
    deeplink: null,
    generateNewApiKeyDialog: false,
    editDeviceDialog: false,
    editedDeviceIndex: -1,
    editedDevice: {
      alias: ""
    },
    signalRId: null,
    associateType: "qr",
    selectedItem: null,
    isRemoving: false,
    removeDeviceDialog: false,
    getProfileError: false,
    loading: false,
    page: {
      title: "Dispositivos"
    },
    headers: [
      {
        text: "ALIAS",
        value: "alias"
      },
      { text: "ID", value: "uid" },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    aliasRules: [
      v => !!v || "Se requiere alias",
      v =>
        (v && v.length <= 40) || "El alias no debe sobrepasar los 40 caracteres"
    ]
  }),

  created() {
    this.userDevices = this.$store.state.user.userProfile.devices;
    this.getUserProfile();
    this.$notificationsHub.$on("disconnected", this.handleTraceEvent);
  },

  computed: {
    userProfile() {
      return this.$store.getters["user/userProfile"];
    },
    havePlanWriteMode() {
      return this.$store.getters.havePlanWriteMode;
    }
  },
  watch: {
    removeDeviceDialog(val) {
      val || this.closeRemove();
    },
    removeQRDialog(val) {
      val || this.closeQR();
    }
  },

  methods: {
    processNewDeviceCreated() {
      this.associateDeviceDialogQR = false;
      this.associateDeviceDialogDeeplink = false;
      this.$toasted.global.toast_success({
        message: "El dispositivo se ha añadido correctamente"
      });
      this.getUserProfile();
    },
    handleTraceEvent() {
      this.listenForNewDevice();
    },
    listenForNewDevice() {
      this.$notificationsHub.addEventListener(
        `NewDevice(${this.signalRId})`,
        (deviceId, uid, alias) => {
          this.$notificationsHub.removeEventListener(
            `NewDevice(${this.signalRId})`
          );
          this.processNewDeviceCreated();
          console.log(
            `New device. Id: ${deviceId}, UniqueId: ${uid}, Alias: ${alias}`
          );
        }
      );
    },
    getUserProfile() {
      this.loading = true;
      this.$store
        .dispatch("user/getUserProfile")
        .then(() => {
          this.loading = false;
          this.userDevices = [];
          setTimeout(() => {
            this.userDevices = this.$store.state.user.userProfile.devices;
          }, 50);
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$toasted.global.toast_error({
            message: "No se ha podido actualizar la tabla"
          });
        });
    },

    getFavoriteIcon(item) {
      if (item.deviceId === this.userProfile.deviceId) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },

    removeItem(item) {
      this.selectedItem = item;
      this.removeDeviceDialog = true;
    },

    removeTextConfirmation() {
      if (this.selectedItem != null) {
        return (
          "¿Desea confirmar la eliminación del dispositivo <strong>" +
          this.selectedItem.alias +
          "</strong>?"
        );
      }
      return "";
    },

    makeFavItem(item) {
      console.log(item);
      var newProfile = this.userProfile;
      newProfile.deviceId = item.deviceId;
      this.$store
        .dispatch("user/setUserProfile", newProfile)
        .then(response => {
          if (response.status === 200) {
            this.processSuccessEditResponse();
          } else {
            this.processErrorEditResponse();
          }
        })
        .catch(error => {
          console.log(error);
          this.processErrorEditResponse();
        });
    },

    closeRemove() {
      this.removeDeviceDialog = false;
    },

    closeQR() {
      this.associateDeviceDialogQR = false;
    },

    removeItemConfirm() {
      this.isRemoving = true;
      this.removeDeviceApi();
    },

    removeDeviceApi() {
      var newProfile = this.userProfile;
      newProfile.devices = newProfile.devices.filter(
        item => item !== this.selectedItem
      );
      this.$store
        .dispatch("user/setUserProfile", newProfile)
        .then(response => {
          if (response.status === 200) {
            this.processSuccessEditResponse();
          } else {
            this.processErrorEditResponse();
          }
        })
        .catch(error => {
          this.processErrorEditResponse();
          console.log(error);
        });
    },

    async associateDevice() {
      if (this.$refs.form.validate()) {
        const requestId = `${Date.now()}.${Math.floor(Math.random() * 100)}`; // Genera un identificador único para la petición
        this.isLoading = true;

        let params = {
          alias: this.editedDevice.alias,
          requestId: requestId
        };
        this.signalRId = requestId;
        if (this.associateType === "qr") {
          this.$store
            .dispatch("getQRCodeUserDevice", params)
            .then(qrCode => {
              this.associateDeviceDialogQR = true;

              setTimeout(() => {
                let canvas = this.$refs.qrcode_canvas;
                QRCode.toCanvas(canvas, qrCode, () => {
                  this.listenForNewDevice();
                });
                this.isLoading = false;
                this.associateDeviceDialog = false;
              }, 0);
            })
            .catch(error => {
              this.associateDeviceDialogQR = false;
              this.associateDeviceDialog = false;
              this.showToastErrorAssociatingDevice();
              console.log(error);
              this.isLoading = false;
            });
        } else if (this.associateType === "deeplink") {
          this.$store
            .dispatch("getDeepLinkUserDevice", params)
            .then(deeplink => {
              this.deeplink = deeplink;
              this.associateDeviceDialogDeeplink = true;
              setTimeout(() => {
                this.listenForNewDevice();

                this.isLoading = false;
                this.associateDeviceDialog = false;
              }, 0);
            })
            .catch(error => {
              this.associateDeviceDialogDeeplink = false;
              this.associateDeviceDialog = false;
              this.showToastErrorAssociatingDevice();
              console.log(error);
              this.isLoading = false;
            });
        }
      }
    },

    showToastErrorAssociatingDevice() {
      this.$toasted.global.toast_error({
        message: "No se ha podido avanzar en la asociación de dispositivo"
      });
    },

    showAssociateDeviceDialog() {
      this.editedDevice = Object.assign({}, null);
      this.associateDeviceDialog = true;

      if (!this.havePlanWriteMode) {
        window.toasted.clear();
        const toastInfo = this.$toasted.global.toast_info({
          message:
            "No podrá asociar un dispositivo con su plan actual. Pulse aquí para mejorar plan."
        });
        toastInfo.el.classList.add("cursor-pointer");
        toastInfo.el.onclick = () => {
          this.$store.dispatch("setSeeUpgradePurchase", true);
        };
      }
    },

    showEditDeviceDialog(device) {
      const index = this.userProfile.devices.findIndex(
        item =>
          item.deviceId === device.deviceId &&
          item.uid === device.uid &&
          item.alias === device.alias
      );
      this.editedDeviceIndex = index;
      this.editedDevice = Object.assign({}, device);
      this.editDeviceDialog = true;
    },

    editDeviceApi() {
      if (this.$refs.form.validate()) {
        var newProfile = this.userProfile;
        newProfile.devices[this.editedDeviceIndex] = this.editedDevice;
        this.isEditing = true;
        this.$store
          .dispatch("user/setUserProfile", newProfile)
          .then(response => {
            if (response.status === 200) {
              this.processSuccessEditResponse();
            } else {
              this.processErrorEditResponse();
            }
          })
          .catch(error => {
            this.processErrorEditResponse();
            console.log(error);
          });
      }
    },

    processSuccessEditResponse() {
      this.userDevices = [];
      setTimeout(() => {
        this.userDevices = this.$store.state.user.userProfile.devices;
      }, 50);

      this.editDeviceDialog = false;
      this.removeDeviceDialog = false;
      this.isEditing = false;
      this.isRemoving = false;
      this.editedDeviceIndex = -1;
      this.editedDevice = { alias: "" };
      this.$toasted.global.toast_success({
        message: "Los cambios se han guardado correctamente"
      });
    },

    processErrorEditResponse() {
      this.isEditing = false;
      this.isRemoving = false;
      this.$toasted.global.toast_error({
        message: "Los cambios no se han podido guardar correctamente"
      });
    },
    copyToClipboard() {
      const textToCopy = this.$refs.deeplink;
      const range = document.createRange();
      range.selectNode(textToCopy);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        this.$toasted.global.toast_success({
          message: "¡Copiado!"
        });
      } catch (err) {
        this.$toasted.global.toast_error({
          message: "Error!"
        });
      }
      window.getSelection().removeAllRanges();
    },
    openAppGooglePlay() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.edatalia.signfromapp",
        "_blank"
      );
    },
    openAppStore() {
      window.open(
        "https://apps.apple.com/us/app/firmaronline-sfa/id1575720223",
        "_blank"
      );
    }
  }
};
</script>
<style lang="scss" scoped>
#canvas-container {
  width: 100%;
  text-align: center;
}
canvas {
  display: inline;
}
@media (max-width: 560px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
