<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="'mdi-signature-freehand'"
    ></BaseBreadcrumb>

    <v-btn-toggle class="mb-8" mandatory v-model="tabSelected" color="primary">
      <v-btn text value="sent" class="px-16 w-auto btn-w-50">
        <h3>Enviados</h3>
      </v-btn>

      <v-btn value="device" class="px-13 w-auto btn-w-50">
        <h3>Dispositivos</h3>
      </v-btn>
    </v-btn-toggle>

    <HistoricFaceToFaceSignatures v-show="tabSelected === 'sent'" />
    <DeviceFaceToFaceSignatures v-show="tabSelected === 'device'" />
  </v-container>
</template>

<script>
import HistoricFaceToFaceSignatures from "./HistoricFaceToFaceSignatures.vue";
import DeviceFaceToFaceSignatures from "./DeviceFaceToFaceSignatures.vue";

export default {
  name: "FaceToFaceSignatures",

  components: {
    HistoricFaceToFaceSignatures,
    DeviceFaceToFaceSignatures
  },

  props: {
    redirect: {
      required: false,
      default: null
    },
    file: {
      required: false,
      default: () => {
        return null;
      }
    },
    docId: {
      type: String,
      required: false
    }
  },

  data: () => ({
    page: {
      title: "Firma Presencial"
    },
    tabSelected: "sent"
  }),

  created() {
    if (this.file != null) {
      this.$router.push({
        name: "NewFaceToFaceSignature",
        params: {
          file: this.file,
          docId: this.docId
        }
      });
    }
  }
};
</script>

<style scoped>
@media (max-width: 560px) {
  .btn-w-50 {
    width: 50%;
  }
}
</style>
